import React, { useState, useEffect } from 'react';
import Navbar from "../navbar/navbar";
import './homepage.css';
import Image from "../my_image/image";
import Toggle from '../toggle/toggle';
import ProjectSlideshow from './ProjectSlideshow';
import { Helmet } from 'react-helmet';
import CanvasBackground from "./background/background";

// Sidebar Component
const Sidebar = () => {
  return (
    <div className="sidebar">
      <a
        href="https://github.com/kipgorey"
        target="_blank"
        rel="noopener noreferrer"
        className="sidebar-icon"
        title="GitHub"
      >
        <i className="fab fa-github"></i>
      </a>
      <a
        href="https://leetcode.com/cgorey"
        target="_blank"
        rel="noopener noreferrer"
        className="sidebar-icon"
        title="LeetCode"
      >
        <i className="fas fa-code"></i>
      </a>
      <a
        href="https://substack.com/@kipgorey"
        target="_blank"
        rel="noopener noreferrer"
        className="sidebar-icon"
        title="Substack"
      >
        <i className="fas fa-pen-nib"></i>
      </a>
    </div>
  );
};

// HomePage Component
const HomePage = () => {
  // Initialize theme state from localStorage, falling back to system preference
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    // If no saved preference, use system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  

  // Toggle theme function
  const toggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-4T31PCY75R"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-4T31PCY75R');
          `}
        </script>
      </Helmet>
      <Navbar />
      <Toggle onClick={toggleTheme} />
      <Sidebar />
      <div className="big-div">
        <div className="home-page">
          <h1 className="name-title">Kip Gorey</h1>
          <h3 className="sub-name-title"> CS+Business @ USC </h3>
          <div className="container">
            <p> I'm a Senior at the University of Southern California studying CS & Business</p>
            <p> Interested in automating tasks in the cyber or physical world using machines.</p>
            <p> Have previous experience in full-stack and NLP projects, research, internships </p>
          </div>
        </div>
        <div className="project-timeline-section">
          <div className="project-section">
            <Image className="profile-image" />
          </div>
        </div>
        <div className="projs">
          <ProjectSlideshow />
        </div>
      </div>
    </>
  );
}

export default HomePage;