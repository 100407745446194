import React, { useState, useEffect } from 'react';
import './ProjectSlideshow.css';

// Import images for projects
import AmpariImage from '../../assets/ampari.png';
import GroupMeImage from '../../assets/group_me.png';
import CodeBaseImage from '../../assets/codeBase.png';

// Define projects with title, image, link
const projects = [
    {
        title: "Ampari",
        image: AmpariImage,
        link: "https://www.ampariai.com",
    },
    {
        title: "CodeBase",
        image: CodeBaseImage,
        link: "https://www.codebasedoc.com",
    },
    {
        title: "Group Me Extension",
        image: GroupMeImage,
        link: "https://fragrant-hospital-618.notion.site/USC-Group-Me-Application-fc9a2b265d13483b8310827dcb885fab?pvs=4",
    }
];

// Project component with title, clickable image
const Project = ({ title, image, link }) => (
    <div className="project">
        <a href={link} target="_blank" rel="noopener noreferrer">
            <img src={image} alt={title} className="project-image" />
        </a>
        <h3 style={{ textDecoration: 'none', color: 'inherit' }}>{title}</h3>
    </div>
);

const ProjectSlideshow = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Switch to the next project every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex === projects.length - 1 ? 0 : prevIndex + 1));
        }, 10000); // 10 seconds

        return () => clearInterval(interval); // Clean up interval on component unmount
    }, []);

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="project-slideshow">
            <h1 className="projects-header">Projects</h1>
            <div className="slideshow-container">
                {/* Display the current project */}
                <Project
                    title={projects[currentIndex].title}
                    image={projects[currentIndex].image}
                    link={projects[currentIndex].link}
                />
                {/* Navigation dots */}
                <div className="dots-container">
                    {projects.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${currentIndex === index ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProjectSlideshow;
