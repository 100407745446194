import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';
import './toggle.css';

function Toggle() {
    // Initialize theme state from localStorage or default to 'light'
    const [theme, setTheme] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme ? savedTheme : 'light'; // Fallback to light if no saved theme
    });

    // Apply theme to the body when theme changes
    useEffect(() => {
        document.body.className = theme;
        localStorage.setItem('theme', theme);  // Save the theme to localStorage
        window.dispatchEvent(new Event('themeChange'));  // Optional: If you need to notify other parts of your app
    }, [theme]); // This effect runs when 'theme' changes

    // Toggle theme between light and dark
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    return (
        <div className="toggle-container" onClick={toggleTheme}>
            {theme === 'light' ? <FaMoon className="icon" /> : <FaSun className="icon" />}
        </div>
    );
}

export default Toggle;
